import {
    useTheme,
    View,
    Image,
    Text,
} from '@aws-amplify/ui-react';

const components = {
    SignIn: {
        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="left" padding={tokens.space.large}>
                    <Text >
                        送信ボタンを押すと本サービスの利用規約とプライバシーポリシーに同意することになります。
                    </Text>
                </View>
            );
        },
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image width="30%"
                        alt="logo"
                        src="logo_top.png"
                    />
                </View>
            );
        }
    },
};

export default components;