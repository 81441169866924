/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  fetchByPath,
  getOverrideProps,
  processFile,
  validateField,
} from "./utils";
import { API } from "aws-amplify";
import { getInterviewRecord } from "../graphql/queries";
import { createInterviewRecord } from "../graphql/mutations";
import { Field } from "@aws-amplify/ui-react/internal";
export default function InterviewRecordUpdateForm(props) {
  const {
    id: idProp,
    interviewRecord: interviewRecordModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    interviewerName: "",
    intervieweeName: "",
    channel: "",
    interviewDateTime: "",
    age: "",
    diagnosis: "",
    EmployeeNumber: "",
    Affiliated: "",
    intervieweeComplaint: "",
    dataPath: undefined,
    status: "",
    transcription: "",
    summary1: "",
    facts: "",
    evaluation: "",
    judgement: "",
    reportPath: "",
  };
  const [interviewerName, setInterviewerName] = React.useState(
    initialValues.interviewerName
  );
  const [intervieweeName, setIntervieweeName] = React.useState(
    initialValues.intervieweeName
  );
  const [channel, setChannel] = React.useState(initialValues.channel);
  const [interviewDateTime, setInterviewDateTime] = React.useState(
    initialValues.interviewDateTime
  );
  const [age, setAge] = React.useState(initialValues.age);
  const [diagnosis, setDiagnosis] = React.useState(initialValues.diagnosis);
  const [EmployeeNumber, setEmployeeNumber] = React.useState(
    initialValues.EmployeeNumber
  );
  const [Affiliated, setAffiliated] = React.useState(initialValues.Affiliated);
  const [intervieweeComplaint, setIntervieweeComplaint] = React.useState(
    initialValues.intervieweeComplaint
  );
  const [dataPath, setDataPath] = React.useState(initialValues.dataPath);
  const [status, setStatus] = React.useState(initialValues.status);
  const [transcription, setTranscription] = React.useState(
    initialValues.transcription
  );
  const [summary1, setSummary1] = React.useState(initialValues.summary1);
  const [facts, setFacts] = React.useState(initialValues.facts);
  const [evaluation, setEvaluation] = React.useState(initialValues.evaluation);
  const [judgement, setJudgement] = React.useState(initialValues.judgement);
  const [reportPath, setReportPath] = React.useState(initialValues.reportPath);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = interviewRecordRecord
      ? { ...initialValues, ...interviewRecordRecord }
      : initialValues;
    setInterviewerName(cleanValues.interviewerName);
    setIntervieweeName(cleanValues.intervieweeName);
    setChannel(cleanValues.channel);
    setInterviewDateTime(cleanValues.interviewDateTime);
    setAge(cleanValues.age);
    setDiagnosis(cleanValues.diagnosis);
    setEmployeeNumber(cleanValues.EmployeeNumber);
    setAffiliated(cleanValues.Affiliated);
    setIntervieweeComplaint(cleanValues.intervieweeComplaint);
    setDataPath(cleanValues.dataPath);
    setStatus(cleanValues.status);
    setTranscription(cleanValues.transcription);
    setSummary1(cleanValues.summary1);
    setFacts(cleanValues.facts);
    setEvaluation(cleanValues.evaluation);
    setJudgement(cleanValues.judgement);
    setReportPath(cleanValues.reportPath);
    setErrors({});
  };
  const [interviewRecordRecord, setInterviewRecordRecord] = React.useState(
    interviewRecordModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
          await API.graphql({
            query: getInterviewRecord.replaceAll("__typename", ""),
            variables: { id: idProp },
          })
        )?.data?.getInterviewRecord
        : interviewRecordModelProp;
      setInterviewRecordRecord(record);
    };
    queryData();
  }, [idProp, interviewRecordModelProp]);
  React.useEffect(resetStateValues, [interviewRecordRecord]);
  const validations = {
    interviewerName: [{ type: "Required" }],
    intervieweeName: [{ type: "Required" }],
    channel: [],
    interviewDateTime: [{ type: "Required" }],
    age: [],
    diagnosis: [],
    EmployeeNumber: [],
    Affiliated: [],
    intervieweeComplaint: [],
    dataPath: [],
    status: [],
    transcription: [],
    summary1: [],
    facts: [],
    evaluation: [],
    judgement: [],
    reportPath: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const convertToLocal = (date) => {
    const df = new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      calendar: "iso8601",
      numberingSystem: "latn",
      hourCycle: "h23",
    });
    const parts = df.formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}`;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          interviewerName,
          intervieweeName,
          channel: channel ?? null,
          interviewDateTime,
          age: age ?? null,
          diagnosis: diagnosis ?? null,
          EmployeeNumber: EmployeeNumber ?? null,
          Affiliated: Affiliated ?? null,
          intervieweeComplaint: intervieweeComplaint ?? null,
          dataPath: dataPath ?? null,
          status: status ?? null,
          transcription: transcription ?? null,
          summary1: summary1 ?? null,
          facts: facts ?? null,
          evaluation: evaluation ?? null,
          judgement: judgement ?? null,
          reportPath: reportPath ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await API.graphql({
            query: createInterviewRecord.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "InterviewRecordUpdateForm")}
      {...rest}
    >
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid0")}
      >
        <TextField
          label="Interviewer name"
          isRequired={true}
          isReadOnly={false}
          value={interviewerName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                interviewerName: value,
                intervieweeName,
                channel,
                interviewDateTime,
                age,
                diagnosis,
                EmployeeNumber,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.interviewerName ?? value;
            }
            if (errors.interviewerName?.hasError) {
              runValidationTasks("interviewerName", value);
            }
            setInterviewerName(value);
          }}
          onBlur={() => runValidationTasks("interviewerName", interviewerName)}
          errorMessage={errors.interviewerName?.errorMessage}
          hasError={errors.interviewerName?.hasError}
          {...getOverrideProps(overrides, "interviewerName")}
        ></TextField>
        <TextField
          label="Interviewee name"
          isRequired={true}
          isReadOnly={false}
          value={intervieweeName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName: value,
                channel,
                interviewDateTime,
                age,
                diagnosis,
                EmployeeNumber,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.intervieweeName ?? value;
            }
            if (errors.intervieweeName?.hasError) {
              runValidationTasks("intervieweeName", value);
            }
            setIntervieweeName(value);
          }}
          onBlur={() => runValidationTasks("intervieweeName", intervieweeName)}
          errorMessage={errors.intervieweeName?.errorMessage}
          hasError={errors.intervieweeName?.hasError}
          {...getOverrideProps(overrides, "intervieweeName")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <SelectField
          label="Channel"
          placeholder="Please select an option"
          isDisabled={false}
          value={channel}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName,
                channel: value,
                interviewDateTime,
                age,
                diagnosis,
                EmployeeNumber,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.channel ?? value;
            }
            if (errors.channel?.hasError) {
              runValidationTasks("channel", value);
            }
            setChannel(value);
          }}
          onBlur={() => runValidationTasks("channel", channel)}
          errorMessage={errors.channel?.errorMessage}
          hasError={errors.channel?.hasError}
          {...getOverrideProps(overrides, "channel")}
        >
          <option
            children="本人"
            value="本人"
            {...getOverrideProps(overrides, "channeloption0")}
          ></option>
          <option
            children="職場"
            value="職場"
            {...getOverrideProps(overrides, "channeloption1")}
          ></option>
          <option
            children="復職支援"
            value="復職支援"
            {...getOverrideProps(overrides, "channeloption2")}
          ></option>
          <option
            children="その他"
            value="その他"
            {...getOverrideProps(overrides, "channeloption3")}
          ></option>
        </SelectField>
        <TextField
          label="Interview date time"
          isRequired={true}
          isReadOnly={false}
          type="datetime-local"
          value={
            interviewDateTime && convertToLocal(new Date(interviewDateTime))
          }
          onChange={(e) => {
            let value =
              e.target.value === ""
                ? ""
                : new Date(e.target.value).toISOString();
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName,
                channel,
                interviewDateTime: value,
                age,
                diagnosis,
                EmployeeNumber,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.interviewDateTime ?? value;
            }
            if (errors.interviewDateTime?.hasError) {
              runValidationTasks("interviewDateTime", value);
            }
            setInterviewDateTime(value);
          }}
          onBlur={() =>
            runValidationTasks("interviewDateTime", interviewDateTime)
          }
          errorMessage={errors.interviewDateTime?.errorMessage}
          hasError={errors.interviewDateTime?.hasError}
          {...getOverrideProps(overrides, "interviewDateTime")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid2")}
      >
        <TextField
          label="Age"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={age}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName,
                channel,
                interviewDateTime,
                age: value,
                diagnosis,
                EmployeeNumber,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.age ?? value;
            }
            if (errors.age?.hasError) {
              runValidationTasks("age", value);
            }
            setAge(value);
          }}
          onBlur={() => runValidationTasks("age", age)}
          errorMessage={errors.age?.errorMessage}
          hasError={errors.age?.hasError}
          {...getOverrideProps(overrides, "age")}
        ></TextField>
        <TextField
          label="Diagnosis"
          isRequired={false}
          isReadOnly={false}
          value={diagnosis}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName,
                channel,
                interviewDateTime,
                age,
                diagnosis: value,
                EmployeeNumber,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.diagnosis ?? value;
            }
            if (errors.diagnosis?.hasError) {
              runValidationTasks("diagnosis", value);
            }
            setDiagnosis(value);
          }}
          onBlur={() => runValidationTasks("diagnosis", diagnosis)}
          errorMessage={errors.diagnosis?.errorMessage}
          hasError={errors.diagnosis?.hasError}
          {...getOverrideProps(overrides, "diagnosis")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Employee number"
          isRequired={false}
          isReadOnly={false}
          value={EmployeeNumber}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName,
                channel,
                interviewDateTime,
                age,
                diagnosis,
                EmployeeNumber: value,
                Affiliated,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.EmployeeNumber ?? value;
            }
            if (errors.EmployeeNumber?.hasError) {
              runValidationTasks("EmployeeNumber", value);
            }
            setEmployeeNumber(value);
          }}
          onBlur={() => runValidationTasks("EmployeeNumber", EmployeeNumber)}
          errorMessage={errors.EmployeeNumber?.errorMessage}
          hasError={errors.EmployeeNumber?.hasError}
          {...getOverrideProps(overrides, "EmployeeNumber")}
        ></TextField>
        <TextField
          label="Affiliated"
          isRequired={false}
          isReadOnly={false}
          value={Affiliated}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                interviewerName,
                intervieweeName,
                channel,
                interviewDateTime,
                age,
                diagnosis,
                EmployeeNumber,
                Affiliated: value,
                intervieweeComplaint,
                dataPath,
                status,
                transcription,
                summary1,
                facts,
                evaluation,
                judgement,
                reportPath,
              };
              const result = onChange(modelFields);
              value = result?.Affiliated ?? value;
            }
            if (errors.Affiliated?.hasError) {
              runValidationTasks("Affiliated", value);
            }
            setAffiliated(value);
          }}
          onBlur={() => runValidationTasks("Affiliated", Affiliated)}
          errorMessage={errors.Affiliated?.errorMessage}
          hasError={errors.Affiliated?.hasError}
          {...getOverrideProps(overrides, "Affiliated")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Interviewee complaint"
        isRequired={false}
        isReadOnly={false}
        value={intervieweeComplaint}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint: value,
              dataPath,
              status,
              transcription,
              summary1,
              facts,
              evaluation,
              judgement,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.intervieweeComplaint ?? value;
          }
          if (errors.intervieweeComplaint?.hasError) {
            runValidationTasks("intervieweeComplaint", value);
          }
          setIntervieweeComplaint(value);
        }}
        onBlur={() =>
          runValidationTasks("intervieweeComplaint", intervieweeComplaint)
        }
        errorMessage={errors.intervieweeComplaint?.errorMessage}
        hasError={errors.intervieweeComplaint?.hasError}
        {...getOverrideProps(overrides, "intervieweeComplaint")}
      ></TextAreaField>
      <Field
        errorMessage={errors.dataPath?.errorMessage}
        hasError={errors.dataPath?.hasError}
        label={
          "\u65B0\u3057\u3044\u97F3\u58F0\u30D5\u30A1\u30A4\u30EB\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9"
        }
        isRequired={false}
        isReadOnly={false}
      >
        {interviewRecordRecord && (
          <StorageManager
            defaultFiles={[{ key: interviewRecordRecord.dataPath }]}
            onUploadSuccess={({ key }) => {
              setDataPath((prev) => {
                let value = key;
                if (onChange) {
                  const modelFields = {
                    interviewerName,
                    intervieweeName,
                    channel,
                    interviewDateTime,
                    age,
                    diagnosis,
                    EmployeeNumber,
                    Affiliated,
                    intervieweeComplaint,
                    dataPath: value,
                    status,
                    transcription,
                    summary1,
                    facts,
                    evaluation,
                    judgement,
                    reportPath,
                  };
                  const result = onChange(modelFields);
                  value = result?.dataPath ?? value;
                }
                return value;
              });
            }}
            onFileRemove={({ key }) => {
              setDataPath((prev) => {
                let value = initialValues?.dataPath;
                if (onChange) {
                  const modelFields = {
                    interviewerName,
                    intervieweeName,
                    channel,
                    interviewDateTime,
                    age,
                    diagnosis,
                    EmployeeNumber,
                    Affiliated,
                    intervieweeComplaint,
                    dataPath: value,
                    status,
                    transcription,
                    summary1,
                    facts,
                    evaluation,
                    judgement,
                  };
                  const result = onChange(modelFields);
                  value = result?.dataPath ?? value;
                }
                return value;
              });
            }}
            processFile={processFile}
            accessLevel={"private"}
            acceptedFileTypes={[]}
            isResumable={false}
            showThumbnails={true}
            maxFileCount={1}
            {...getOverrideProps(overrides, "dataPath")}
          ></StorageManager>
        )}
      </Field>
      <SelectField
        label="Status"
        placeholder="Please select an option"
        isDisabled={false}
        value={status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status: value,
              transcription,
              summary1,
              facts,
              evaluation,
              judgement,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.status ?? value;
          }
          if (errors.status?.hasError) {
            runValidationTasks("status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("status", status)}
        errorMessage={errors.status?.errorMessage}
        hasError={errors.status?.hasError}
        {...getOverrideProps(overrides, "status")}
      >
        <option
          children="解析中"
          value="ANALYZING"
          {...getOverrideProps(overrides, "statusoption0")}
        ></option>
        <option
          children="完了"
          value="COMPLETE"
          {...getOverrideProps(overrides, "statusoption1")}
        ></option>
        <option
          children="再解析中"
          value="REANALYZING"
          {...getOverrideProps(overrides, "statusoption2")}
        ></option>
        <option
          children="エラー"
          value="ERROR"
          {...getOverrideProps(overrides, "statusoption3")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Transcription"
        isRequired={false}
        isReadOnly={false}
        value={transcription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status,
              transcription: value,
              summary1,
              facts,
              evaluation,
              judgement,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.transcription ?? value;
          }
          if (errors.transcription?.hasError) {
            runValidationTasks("transcription", value);
          }
          setTranscription(value);
        }}
        onBlur={() => runValidationTasks("transcription", transcription)}
        errorMessage={errors.transcription?.errorMessage}
        hasError={errors.transcription?.hasError}
        {...getOverrideProps(overrides, "transcription")}
      ></TextAreaField>
      <TextAreaField
        label="Summary1"
        isRequired={false}
        isReadOnly={false}
        value={summary1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status,
              transcription,
              summary1: value,
              facts,
              evaluation,
              judgement,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.summary1 ?? value;
          }
          if (errors.summary1?.hasError) {
            runValidationTasks("summary1", value);
          }
          setSummary1(value);
        }}
        onBlur={() => runValidationTasks("summary1", summary1)}
        errorMessage={errors.summary1?.errorMessage}
        hasError={errors.summary1?.hasError}
        {...getOverrideProps(overrides, "summary1")}
      ></TextAreaField>
      <TextAreaField
        label="Facts"
        isRequired={false}
        isReadOnly={false}
        value={facts}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status,
              transcription,
              summary1,
              facts: value,
              evaluation,
              judgement,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.facts ?? value;
          }
          if (errors.facts?.hasError) {
            runValidationTasks("facts", value);
          }
          setFacts(value);
        }}
        onBlur={() => runValidationTasks("facts", facts)}
        errorMessage={errors.facts?.errorMessage}
        hasError={errors.facts?.hasError}
        {...getOverrideProps(overrides, "facts")}
      ></TextAreaField>
      <TextAreaField
        label="Evaluation"
        isRequired={false}
        isReadOnly={false}
        value={evaluation}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status,
              transcription,
              summary1,
              facts,
              evaluation: value,
              judgement,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.evaluation ?? value;
          }
          if (errors.evaluation?.hasError) {
            runValidationTasks("evaluation", value);
          }
          setEvaluation(value);
        }}
        onBlur={() => runValidationTasks("evaluation", evaluation)}
        errorMessage={errors.evaluation?.errorMessage}
        hasError={errors.evaluation?.hasError}
        {...getOverrideProps(overrides, "evaluation")}
      ></TextAreaField>
      <TextAreaField
        label="Judgement"
        isRequired={false}
        isReadOnly={false}
        value={judgement}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status,
              transcription,
              summary1,
              facts,
              evaluation,
              judgement: value,
              reportPath,
            };
            const result = onChange(modelFields);
            value = result?.judgement ?? value;
          }
          if (errors.judgement?.hasError) {
            runValidationTasks("judgement", value);
          }
          setJudgement(value);
        }}
        onBlur={() => runValidationTasks("judgement", judgement)}
        errorMessage={errors.judgement?.errorMessage}
        hasError={errors.judgement?.hasError}
        {...getOverrideProps(overrides, "judgement")}
      ></TextAreaField>
      <TextField
        label="Report path"
        isRequired={false}
        isReadOnly={false}
        value={reportPath}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              interviewerName,
              intervieweeName,
              channel,
              interviewDateTime,
              age,
              diagnosis,
              EmployeeNumber,
              Affiliated,
              intervieweeComplaint,
              dataPath,
              status,
              transcription,
              summary1,
              facts,
              evaluation,
              judgement,
              reportPath: value,
            };
            const result = onChange(modelFields);
            value = result?.reportPath ?? value;
          }
          if (errors.reportPath?.hasError) {
            runValidationTasks("reportPath", value);
          }
          setReportPath(value);
        }}
        onBlur={() => runValidationTasks("reportPath", reportPath)}
        errorMessage={errors.reportPath?.errorMessage}
        hasError={errors.reportPath?.hasError}
        {...getOverrideProps(overrides, "reportPath")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="再解析"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || interviewRecordModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
          <Button
            children="Excel帳票ダウンロード"
            variation="primary"
            isDisabled={
              !(idProp || interviewRecordModelProp) || reportPath === null || reportPath.trim() === ""
            }
            {...getOverrideProps(overrides, "reportButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
