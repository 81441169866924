import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./pages/Main";
import reportWebVitals from "./reportWebVitals";

import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider, Theme } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme: Theme = {
  name: "studioTheme",
  tokens: {
    components: {
      authenticator: {
        modal: {
          backgroundColor: { value: "{colors.white}" },
        },
        router: {
          boxShadow: { value: "none" },
          borderColor: {value: "transparent" },
        },
      },
    },
    colors: {
      brand: {
        primary: { 
          "10": { value: "#fff7ed" },
          "20": { value: "#fed7aa" },
          "40": { value: "#fb923c" },
          "60": { value: "#ea580c" },
          "80": { value: "#9a3412" },
          "90": { value: "#7c2d12" },
          "100": { value: "#451a03" },
         },
      }
    },
    fonts: {
      default: {
        static: {value: "'Noto Sans JP', sans-serif;"},
        variable: {value: "'Noto Sans JP', sans-serif;"},
      }
    },
    radii: {
      small: { value: "6px" },
    }
  },
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
