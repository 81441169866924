/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInterviewRecord = /* GraphQL */ `query GetInterviewRecord($id: ID!) {
  getInterviewRecord(id: $id) {
    id
    interviewerName
    intervieweeName
    channel
    interviewDateTime
    age
    diagnosis
    EmployeeNumber
    Affiliated
    intervieweeComplaint
    dataPath
    status
    transcription
    summary1
    facts
    evaluation
    judgement
    reportPath
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInterviewRecordQueryVariables,
  APITypes.GetInterviewRecordQuery
>;
export const listInterviewRecords = /* GraphQL */ `query ListInterviewRecords(
  $filter: ModelInterviewRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listInterviewRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      interviewerName
      intervieweeName
      channel
      interviewDateTime
      age
      diagnosis
      EmployeeNumber
      Affiliated
      intervieweeComplaint
      dataPath
      status
      transcription
      summary1
      facts
      evaluation
      judgement
      reportPath
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInterviewRecordsQueryVariables,
  APITypes.ListInterviewRecordsQuery
>;
export const getUserComment = /* GraphQL */ `query GetUserComment($id: ID!) {
  getUserComment(id: $id) {
    id
    text
    InterviewRecordId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserCommentQueryVariables,
  APITypes.GetUserCommentQuery
>;
export const listUserComments = /* GraphQL */ `query ListUserComments(
  $filter: ModelUserCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      text
      InterviewRecordId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserCommentsQueryVariables,
  APITypes.ListUserCommentsQuery
>;
