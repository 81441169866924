/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function RecordCard(props) {
  const { interviewRecord, overrides, ...rest } = props;
  return (
    <Flex
      gap="5px"
      direction="row"
      width="250px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="10px 0px 10px 0px"
      {...getOverrideProps(overrides, "RecordCard")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 17px 0px 17px"
        {...getOverrideProps(overrides, "Product Title")}
      >
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.01px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={interviewRecord?.createdAt}
          {...getOverrideProps(overrides, "2022-09-04T16:07:48")}
        ></Text>
        <Text
          fontFamily="Noto Sans JP"
          fontSize="16px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={interviewRecord?.intervieweeName}
          {...getOverrideProps(
            overrides,
            "\u7530\u4E2D\u3000\u30BF\u30ED\u30A6"
          )}
        ></Text>
      </Flex>
      <Image
        width="20px"
        height="22px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="delete.png"
        {...getOverrideProps(overrides, "delete")}
      ></Image>
    </Flex>
  );
}
