import React, { FC, useContext, useEffect, useState } from "react";
import { RecordContext } from "./index";
import "./Content.css";
import {
  InterviewRecordCreateForm,
  UserCommentCreateForm,
  InterviewRecordUpdateForm,
} from "../../ui-components";
import { Flex, Text, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { GraphQLQuery } from "@aws-amplify/api";
import { Amplify, API, Storage } from "aws-amplify";
import { GetInterviewRecordQuery, InterviewRecordStatus } from "../../API";
import * as queries from "../../graphql/queries";

function getCurrentDateTimeWithTimezone() {
  const now = new Date();

  // Date to YYYY-MM-DD format
  const datePart = now.toISOString().split("T")[0];

  // Time to hh:mm:ss format
  const timePart = now.toTimeString().split(" ")[0];

  // Get timezone offset in minutes
  const timezoneOffset = now.getTimezoneOffset();

  // Convert timezone offset to hh:mm format
  const sign = timezoneOffset < 0 ? "+" : "-";
  const absoluteOffset = Math.abs(timezoneOffset);
  const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, "0");
  const minutes = String(absoluteOffset % 60).padStart(2, "0");
  const timezone = `${sign}${hours}:${minutes}`;

  return `${datePart}T${timePart}${timezone}`;
}

const Content: FC = () => {
  const context = useContext(RecordContext);
  const [authPath, setAuthPath] = useState("");

  const { record, setRecord } = context;
  // InterviewRecordを取得する関数
  async function getInterviewRecords(id: string) {
    const res = await API.graphql<GraphQLQuery<GetInterviewRecordQuery>>({
      query: queries.getInterviewRecord,
      variables: { id: id },
    });
    if (!res.data?.getInterviewRecord) {
      return;
    }
    setRecord(res.data.getInterviewRecord);
  }

  useEffect(() => {
    const fetchAuthPath = async () => {
      const credentials = await Amplify.Auth.currentUserCredentials();
      setAuthPath(credentials.identityId);
    };
    fetchAuthPath();
  }, []);

  // recordのidが空文字列の場合は、新規作成画面を表示する
  if (record.id === "") {
    return (
      <div className="content">
        <InterviewRecordCreateForm
          // 上書き防止のため、ファイル名を変更する
          overrides={{
            interviewerName: {
              label: "面接担当者",
              errorMessage: "必須項目です",
            },
            intervieweeName: {
              label: "名前",
              errorMessage: "必須項目です",
            },
            channel: { label: "相談経路", placeholder: "選択してください。" },
            interviewDateTime: {
              label: "相談日時",
              errorMessage: "必須項目です",
            },
            age: { label: "年齢" },
            diagnosis: { label: "診断名" },
            EmployeeNumber: { label: "職番" },
            Affiliated: { label: "所属" },
            intervieweeComplaint: { label: "当初の訴え" },
            dataPath: {
              accessLevel: "private",
              acceptedFileTypes: [".m4a"],
              maxFileCount: 1,
              processFile: ({ file }) => {
                const fileName = file.name.split(".")[0];
                const fileExtension = file.name.split(".")[1];
                return {
                  file,
                  key: `${fileName}_${getCurrentDateTimeWithTimezone()}.${fileExtension}`,
                };
              },
              displayText: {
                browseFilesText: "ファイルを選択",
                dropFilesText: "ファイルをドロップ",
                getFilesUploadedText(count) {
                  return `ファイルがアップロードされました`;
                },
                uploadSuccessfulText: "アップロード完了",
              },
            },
            status: {
              display: "none",
            },
            transcription: {
              display: "none",
            },
            summary1: {
              display: "none",
            },
            judgement: {
              display: "none",
            },
            facts: {
              display: "none",
            },
            evaluation: {
              display: "none",
            },
            reportPath: {
              display: "none",
            },
          }}
          onError={(data, error) => {
            console.log(data);
            console.log(error);
          }}
          onSubmit={(data) => {
            data.status = InterviewRecordStatus.ANALYZING;
            data.transcription = "処理中です。データ更新ボタンを押して下さい。";
            data.summary1 = "処理中です。データ更新ボタンを押して下さい。";
            data.judgement = "処理中です。データ更新ボタンを押して下さい。";
            data.facts = "処理中です。データ更新ボタンを押して下さい。";
            data.evaluation = "処理中です。データ更新ボタンを押して下さい。";
            //　ファイル名をs3パスと合わせるため
            data.dataPath = "private/" + authPath + "/" + data.dataPath;
            console.log(data);
            return data;
          }}
          onSuccess={(data) => {
            window.location.reload();
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="content">
        <InterviewRecordUpdateForm
          interviewRecord={record}
          // 上書き防止のため、ファイル名を変更する
          overrides={{
            interviewerName: {
              label: "面接担当者",
              errorMessage: "必須項目です",
            },
            intervieweeName: {
              label: "名前",
              errorMessage: "必須項目です",
            },
            channel: { label: "相談経路", placeholder: "選択してください。" },
            interviewDateTime: {
              label: "相談日時",
              errorMessage: "必須項目です",
            },
            age: { label: "年齢" },
            diagnosis: { label: "診断名" },
            EmployeeNumber: { label: "職番" },
            Affiliated: { label: "所属" },
            intervieweeComplaint: { label: "当初の訴え" },
            dataPath: {
              processFile: ({ file }) => {
                const fileName = file.name.split(".")[0];
                const fileExtension = file.name.split(".")[1];
                return {
                  file,
                  key: `${fileName}_${getCurrentDateTimeWithTimezone()}.${fileExtension}`,
                };
              },
              defaultFiles: [],
              displayText: {
                browseFilesText: "ファイルを選択",
                dropFilesText: "ファイルをドロップ",
                getFilesUploadedText(count) {
                  return `${count} ファイルがアップロードされました`;
                },
              },
            },
            status: {
              label: "ステータス",
              isDisabled: true,
            },
            transcription: {
              rows: 10,
              resize: "vertical",
              label: (
                <Flex direction="row" alignItems={"center"}>
                  <Text>音声</Text>
                  <Button
                    children="コピー"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        String(record.transcription)
                      );
                    }}
                  ></Button>
                  <Button
                    onClick={() => {
                      if (record.id !== "") {
                        getInterviewRecords(record.id);
                      }
                    }}
                    size="small"
                    children="データ更新"
                  ></Button>
                </Flex>
              ),
            },
            summary1: {
              rows: 10,
              resize: "vertical",
              label: (
                <Flex direction="row" alignItems={"center"}>
                  <Text>要約</Text>
                  <Button
                    children="コピー"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(String(record.summary1));
                    }}
                  ></Button>
                  <Button
                    onClick={() => {
                      if (record.id !== "") {
                        getInterviewRecords(record.id);
                      }
                    }}
                    size="small"
                    children="データ更新"
                  ></Button>
                </Flex>
              ),
            },
            judgement: {
              rows: 10,
              resize: "vertical",
              label: (
                <Flex direction="row" alignItems={"center"}>
                  <Text>判断</Text>
                  <Button
                    children="コピー"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(String(record.judgement));
                    }}
                  ></Button>
                  <Button
                    onClick={() => {
                      if (record.id !== "") {
                        getInterviewRecords(record.id);
                      }
                    }}
                    size="small"
                    children="データ更新"
                  ></Button>
                </Flex>
              ),
            },
            facts: {
              rows: 10,
              resize: "vertical",
              label: (
                <Flex direction="row" alignItems={"center"}>
                  <Text>ファクト</Text>
                  <Button
                    children="コピー"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(String(record.facts));
                    }}
                  ></Button>
                  <Button
                    onClick={() => {
                      if (record.id !== "") {
                        getInterviewRecords(record.id);
                      }
                    }}
                    size="small"
                    children="データ更新"
                  ></Button>
                </Flex>
              ),
            },
            evaluation: {
              rows: 10,
              resize: "vertical",
              label: (
                <Flex direction="row" alignItems={"center"}>
                  <Text>評価</Text>
                  <Button
                    children="コピー"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(String(record.evaluation));
                    }}
                  ></Button>
                  <Button
                    onClick={() => {
                      if (record.id !== "") {
                        getInterviewRecords(record.id);
                      }
                    }}
                    size="small"
                    children="データ更新"
                  ></Button>
                </Flex>
              ),
            },
            reportPath: {
              display: "none",
            },
            reportButton: {
              onClick: async () => {
                if (!record.reportPath || !record.reportPath.trim()) {
                  return;
                }
                const key = record.reportPath!.split("/")[2];

                Storage.configure({ level: "private" });
                const result = await Storage.get(key, { download: true });

                const fileName =
                  record.interviewDateTime.substring(0, 10) +
                  "_" +
                  record.intervieweeName +
                  "_面接記録.xlsx";

                const link = document.createElement("a");
                link.download = fileName;
                link.href = URL.createObjectURL(result.Body!);
                link.click();
                URL.revokeObjectURL(link.href);
              },
            },
          }}
          onError={(data, error) => {
            console.log(data);
            console.log(error);
          }}
          onSubmit={(data) => {
            data.status = InterviewRecordStatus.REANALYZING;
            data.transcription = "処理中です。データ更新ボタンを押して下さい。";
            data.summary1 = "処理中です。データ更新ボタンを押して下さい。";
            data.judgement = "処理中です。データ更新ボタンを押して下さい。";
            data.facts = "処理中です。データ更新ボタンを押して下さい。";
            data.evaluation = "処理中です。データ更新ボタンを押して下さい。";
            //　ファイル名をs3パスと合わせるため
            const pathHead = data.dataPath?.split("/")[0];
            // authPathが複数追加されるのを防ぐ
            if (pathHead === "private") {
              return data;
            } else {
              data.dataPath = "private/" + authPath + "/" + data.dataPath;
              return data;
            }
          }}
          onSuccess={(data) => {
            window.location.reload();
          }}
        />
        <UserCommentCreateForm
          overrides={{
            InterviewRecordId: {
              display: "none",
            },
          }}
          onSubmit={(data) => {
            data.InterviewRecordId = record.id;
            return data;
          }}
          onError={(data, error) => {
            console.log(error);
          }}
          onSuccess={(data) => {
            window.location.reload();
          }}
        />
      </div>
    );
  }
};

export default Content;
