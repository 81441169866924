import React, {
  useState,
  createContext,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import Sidebar from "./Sidebar";
import Content from "./Content";
import components from "../Auth";
import "./index.css";
import { withAuthenticator, translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { InterviewRecord } from "../../API";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

// ここで定義した型を使って、Contextの型を定義する
interface RecordContextType {
  record: InterviewRecord;
  setRecord: Dispatch<SetStateAction<InterviewRecord>>;
}

export const RecordContext = createContext<RecordContextType>({
  record: {
    __typename: "InterviewRecord",
    id: "",
    intervieweeName: "",
    interviewerName: "",
    interviewDateTime: "",
    channel: "",
    createdAt: "",
    updatedAt: "",
  },
  setRecord: () => {},
});

const Main: FC = () => {
  // contextを初期化
  const [record, setRecord] = useState<InterviewRecord>({
    __typename: "InterviewRecord",
    id: "",
    intervieweeName: "",
    interviewerName: "",
    interviewDateTime: "",
    channel: "",
    createdAt: "",
    updatedAt: "",
    dataPath: "",
    intervieweeComplaint: "",
    judgement: "",
    owner: "",
    summary1: "",
    transcription: "",
  });

  return (
    <RecordContext.Provider value={{ record: record, setRecord: setRecord }}>
      <div className="main">
        <Sidebar />
        <Content />
      </div>
    </RecordContext.Provider>
  );
};

export default withAuthenticator(Main, {
  hideSignUp: true,
  components: components,
  formFields: {
    signIn: {
      username: {
        placeholder: "ユーザー名を入力",
      }
    }
  }
});
