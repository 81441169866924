/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateInterviewRecordInput = {
  id?: string | null,
  interviewerName: string,
  intervieweeName: string,
  channel?: string | null,
  interviewDateTime: string,
  age?: number | null,
  diagnosis?: string | null,
  EmployeeNumber?: string | null,
  Affiliated?: string | null,
  intervieweeComplaint?: string | null,
  dataPath?: string | null,
  status?: InterviewRecordStatus | null,
  transcription?: string | null,
  summary1?: string | null,
  facts?: string | null,
  evaluation?: string | null,
  judgement?: string | null,
  reportPath?: string | null,
};

export enum InterviewRecordStatus {
  ANALYZING = "ANALYZING",
  COMPLETE = "COMPLETE",
  REANALYZING = "REANALYZING",
  ERROR = "ERROR",
}


export type ModelInterviewRecordConditionInput = {
  interviewerName?: ModelStringInput | null,
  intervieweeName?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  interviewDateTime?: ModelStringInput | null,
  age?: ModelIntInput | null,
  diagnosis?: ModelStringInput | null,
  EmployeeNumber?: ModelStringInput | null,
  Affiliated?: ModelStringInput | null,
  intervieweeComplaint?: ModelStringInput | null,
  dataPath?: ModelStringInput | null,
  status?: ModelInterviewRecordStatusInput | null,
  transcription?: ModelStringInput | null,
  summary1?: ModelStringInput | null,
  facts?: ModelStringInput | null,
  evaluation?: ModelStringInput | null,
  judgement?: ModelStringInput | null,
  reportPath?: ModelStringInput | null,
  and?: Array< ModelInterviewRecordConditionInput | null > | null,
  or?: Array< ModelInterviewRecordConditionInput | null > | null,
  not?: ModelInterviewRecordConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelInterviewRecordStatusInput = {
  eq?: InterviewRecordStatus | null,
  ne?: InterviewRecordStatus | null,
};

export type InterviewRecord = {
  __typename: "InterviewRecord",
  id: string,
  interviewerName: string,
  intervieweeName: string,
  channel?: string | null,
  interviewDateTime: string,
  age?: number | null,
  diagnosis?: string | null,
  EmployeeNumber?: string | null,
  Affiliated?: string | null,
  intervieweeComplaint?: string | null,
  dataPath?: string | null,
  status?: InterviewRecordStatus | null,
  transcription?: string | null,
  summary1?: string | null,
  facts?: string | null,
  evaluation?: string | null,
  judgement?: string | null,
  reportPath?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateInterviewRecordInput = {
  id: string,
  interviewerName?: string | null,
  intervieweeName?: string | null,
  channel?: string | null,
  interviewDateTime?: string | null,
  age?: number | null,
  diagnosis?: string | null,
  EmployeeNumber?: string | null,
  Affiliated?: string | null,
  intervieweeComplaint?: string | null,
  dataPath?: string | null,
  status?: InterviewRecordStatus | null,
  transcription?: string | null,
  summary1?: string | null,
  facts?: string | null,
  evaluation?: string | null,
  judgement?: string | null,
  reportPath?: string | null,
};

export type DeleteInterviewRecordInput = {
  id: string,
};

export type CreateUserCommentInput = {
  id?: string | null,
  text: string,
  InterviewRecordId?: string | null,
};

export type ModelUserCommentConditionInput = {
  text?: ModelStringInput | null,
  InterviewRecordId?: ModelStringInput | null,
  and?: Array< ModelUserCommentConditionInput | null > | null,
  or?: Array< ModelUserCommentConditionInput | null > | null,
  not?: ModelUserCommentConditionInput | null,
};

export type UserComment = {
  __typename: "UserComment",
  id: string,
  text: string,
  InterviewRecordId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserCommentInput = {
  id: string,
  text?: string | null,
  InterviewRecordId?: string | null,
};

export type DeleteUserCommentInput = {
  id: string,
};

export type ModelInterviewRecordFilterInput = {
  id?: ModelIDInput | null,
  interviewerName?: ModelStringInput | null,
  intervieweeName?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  interviewDateTime?: ModelStringInput | null,
  age?: ModelIntInput | null,
  diagnosis?: ModelStringInput | null,
  EmployeeNumber?: ModelStringInput | null,
  Affiliated?: ModelStringInput | null,
  intervieweeComplaint?: ModelStringInput | null,
  dataPath?: ModelStringInput | null,
  status?: ModelInterviewRecordStatusInput | null,
  transcription?: ModelStringInput | null,
  summary1?: ModelStringInput | null,
  facts?: ModelStringInput | null,
  evaluation?: ModelStringInput | null,
  judgement?: ModelStringInput | null,
  reportPath?: ModelStringInput | null,
  and?: Array< ModelInterviewRecordFilterInput | null > | null,
  or?: Array< ModelInterviewRecordFilterInput | null > | null,
  not?: ModelInterviewRecordFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelInterviewRecordConnection = {
  __typename: "ModelInterviewRecordConnection",
  items:  Array<InterviewRecord | null >,
  nextToken?: string | null,
};

export type ModelUserCommentFilterInput = {
  id?: ModelIDInput | null,
  text?: ModelStringInput | null,
  InterviewRecordId?: ModelStringInput | null,
  and?: Array< ModelUserCommentFilterInput | null > | null,
  or?: Array< ModelUserCommentFilterInput | null > | null,
  not?: ModelUserCommentFilterInput | null,
};

export type ModelUserCommentConnection = {
  __typename: "ModelUserCommentConnection",
  items:  Array<UserComment | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionInterviewRecordFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  interviewerName?: ModelSubscriptionStringInput | null,
  intervieweeName?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  interviewDateTime?: ModelSubscriptionStringInput | null,
  age?: ModelSubscriptionIntInput | null,
  diagnosis?: ModelSubscriptionStringInput | null,
  EmployeeNumber?: ModelSubscriptionStringInput | null,
  Affiliated?: ModelSubscriptionStringInput | null,
  intervieweeComplaint?: ModelSubscriptionStringInput | null,
  dataPath?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  transcription?: ModelSubscriptionStringInput | null,
  summary1?: ModelSubscriptionStringInput | null,
  facts?: ModelSubscriptionStringInput | null,
  evaluation?: ModelSubscriptionStringInput | null,
  judgement?: ModelSubscriptionStringInput | null,
  reportPath?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInterviewRecordFilterInput | null > | null,
  or?: Array< ModelSubscriptionInterviewRecordFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  text?: ModelSubscriptionStringInput | null,
  InterviewRecordId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserCommentFilterInput | null > | null,
};

export type CreateInterviewRecordMutationVariables = {
  input: CreateInterviewRecordInput,
  condition?: ModelInterviewRecordConditionInput | null,
};

export type CreateInterviewRecordMutation = {
  createInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateInterviewRecordMutationVariables = {
  input: UpdateInterviewRecordInput,
  condition?: ModelInterviewRecordConditionInput | null,
};

export type UpdateInterviewRecordMutation = {
  updateInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteInterviewRecordMutationVariables = {
  input: DeleteInterviewRecordInput,
  condition?: ModelInterviewRecordConditionInput | null,
};

export type DeleteInterviewRecordMutation = {
  deleteInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserCommentMutationVariables = {
  input: CreateUserCommentInput,
  condition?: ModelUserCommentConditionInput | null,
};

export type CreateUserCommentMutation = {
  createUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserCommentMutationVariables = {
  input: UpdateUserCommentInput,
  condition?: ModelUserCommentConditionInput | null,
};

export type UpdateUserCommentMutation = {
  updateUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserCommentMutationVariables = {
  input: DeleteUserCommentInput,
  condition?: ModelUserCommentConditionInput | null,
};

export type DeleteUserCommentMutation = {
  deleteUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetInterviewRecordQueryVariables = {
  id: string,
};

export type GetInterviewRecordQuery = {
  getInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListInterviewRecordsQueryVariables = {
  filter?: ModelInterviewRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInterviewRecordsQuery = {
  listInterviewRecords?:  {
    __typename: "ModelInterviewRecordConnection",
    items:  Array< {
      __typename: "InterviewRecord",
      id: string,
      interviewerName: string,
      intervieweeName: string,
      channel?: string | null,
      interviewDateTime: string,
      age?: number | null,
      diagnosis?: string | null,
      EmployeeNumber?: string | null,
      Affiliated?: string | null,
      intervieweeComplaint?: string | null,
      dataPath?: string | null,
      status?: InterviewRecordStatus | null,
      transcription?: string | null,
      summary1?: string | null,
      facts?: string | null,
      evaluation?: string | null,
      judgement?: string | null,
      reportPath?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserCommentQueryVariables = {
  id: string,
};

export type GetUserCommentQuery = {
  getUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserCommentsQueryVariables = {
  filter?: ModelUserCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCommentsQuery = {
  listUserComments?:  {
    __typename: "ModelUserCommentConnection",
    items:  Array< {
      __typename: "UserComment",
      id: string,
      text: string,
      InterviewRecordId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateInterviewRecordSubscriptionVariables = {
  filter?: ModelSubscriptionInterviewRecordFilterInput | null,
  owner?: string | null,
};

export type OnCreateInterviewRecordSubscription = {
  onCreateInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateInterviewRecordSubscriptionVariables = {
  filter?: ModelSubscriptionInterviewRecordFilterInput | null,
  owner?: string | null,
};

export type OnUpdateInterviewRecordSubscription = {
  onUpdateInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteInterviewRecordSubscriptionVariables = {
  filter?: ModelSubscriptionInterviewRecordFilterInput | null,
  owner?: string | null,
};

export type OnDeleteInterviewRecordSubscription = {
  onDeleteInterviewRecord?:  {
    __typename: "InterviewRecord",
    id: string,
    interviewerName: string,
    intervieweeName: string,
    channel?: string | null,
    interviewDateTime: string,
    age?: number | null,
    diagnosis?: string | null,
    EmployeeNumber?: string | null,
    Affiliated?: string | null,
    intervieweeComplaint?: string | null,
    dataPath?: string | null,
    status?: InterviewRecordStatus | null,
    transcription?: string | null,
    summary1?: string | null,
    facts?: string | null,
    evaluation?: string | null,
    judgement?: string | null,
    reportPath?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserCommentSubscriptionVariables = {
  filter?: ModelSubscriptionUserCommentFilterInput | null,
};

export type OnCreateUserCommentSubscription = {
  onCreateUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserCommentSubscriptionVariables = {
  filter?: ModelSubscriptionUserCommentFilterInput | null,
};

export type OnUpdateUserCommentSubscription = {
  onUpdateUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserCommentSubscriptionVariables = {
  filter?: ModelSubscriptionUserCommentFilterInput | null,
};

export type OnDeleteUserCommentSubscription = {
  onDeleteUserComment?:  {
    __typename: "UserComment",
    id: string,
    text: string,
    InterviewRecordId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
