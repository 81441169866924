/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createInterviewRecord = /* GraphQL */ `mutation CreateInterviewRecord(
  $input: CreateInterviewRecordInput!
  $condition: ModelInterviewRecordConditionInput
) {
  createInterviewRecord(input: $input, condition: $condition) {
    id
    interviewerName
    intervieweeName
    channel
    interviewDateTime
    age
    diagnosis
    EmployeeNumber
    Affiliated
    intervieweeComplaint
    dataPath
    status
    transcription
    summary1
    facts
    evaluation
    judgement
    reportPath
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInterviewRecordMutationVariables,
  APITypes.CreateInterviewRecordMutation
>;
export const updateInterviewRecord = /* GraphQL */ `mutation UpdateInterviewRecord(
  $input: UpdateInterviewRecordInput!
  $condition: ModelInterviewRecordConditionInput
) {
  updateInterviewRecord(input: $input, condition: $condition) {
    id
    interviewerName
    intervieweeName
    channel
    interviewDateTime
    age
    diagnosis
    EmployeeNumber
    Affiliated
    intervieweeComplaint
    dataPath
    status
    transcription
    summary1
    facts
    evaluation
    judgement
    reportPath
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInterviewRecordMutationVariables,
  APITypes.UpdateInterviewRecordMutation
>;
export const deleteInterviewRecord = /* GraphQL */ `mutation DeleteInterviewRecord(
  $input: DeleteInterviewRecordInput!
  $condition: ModelInterviewRecordConditionInput
) {
  deleteInterviewRecord(input: $input, condition: $condition) {
    id
    interviewerName
    intervieweeName
    channel
    interviewDateTime
    age
    diagnosis
    EmployeeNumber
    Affiliated
    intervieweeComplaint
    dataPath
    status
    transcription
    summary1
    facts
    evaluation
    judgement
    reportPath
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInterviewRecordMutationVariables,
  APITypes.DeleteInterviewRecordMutation
>;
export const createUserComment = /* GraphQL */ `mutation CreateUserComment(
  $input: CreateUserCommentInput!
  $condition: ModelUserCommentConditionInput
) {
  createUserComment(input: $input, condition: $condition) {
    id
    text
    InterviewRecordId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserCommentMutationVariables,
  APITypes.CreateUserCommentMutation
>;
export const updateUserComment = /* GraphQL */ `mutation UpdateUserComment(
  $input: UpdateUserCommentInput!
  $condition: ModelUserCommentConditionInput
) {
  updateUserComment(input: $input, condition: $condition) {
    id
    text
    InterviewRecordId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserCommentMutationVariables,
  APITypes.UpdateUserCommentMutation
>;
export const deleteUserComment = /* GraphQL */ `mutation DeleteUserComment(
  $input: DeleteUserCommentInput!
  $condition: ModelUserCommentConditionInput
) {
  deleteUserComment(input: $input, condition: $condition) {
    id
    text
    InterviewRecordId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserCommentMutationVariables,
  APITypes.DeleteUserCommentMutation
>;
