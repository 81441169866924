import React, { FC, useContext, useState } from "react";
import { RecordContext } from "./index";
import "./Sidebar.css";
import {
  Button,
  useAuthenticator,
  View,
  Image,
  useTheme,
  Icon,
  Text,
} from "@aws-amplify/ui-react";
import RecordCardCollection from "../../ui-components/RecordCardCollection";
import { InterviewRecord } from "../../API";
import { deleteInterviewRecord } from "../../graphql/mutations";
import { API } from "aws-amplify";
import { MdMoreHoriz } from "react-icons/md";

const Sidebar: FC = () => {
  const authenticator = useAuthenticator();
  const context = useContext(RecordContext);
  const { tokens } = useTheme();
  const { record, setRecord } = context;

  // ポップアップの表示状態を管理するための状態変数
  const [isLogoutPopupVisible, setIsLogoutPopupVisible] = useState(false);

  // ポップアップの表示・非表示を切り替える関数
  const toggleLogoutPopup = () => {
    setIsLogoutPopupVisible(!isLogoutPopupVisible);
  };

  function checkRecord(item: any) {
    // itemはオブジェクトである
    // InterviewRecord型にキャストする
    const record = item as InterviewRecord;
    // record内のnullを""に変換する
    for (const key in record) {
      if (
        record.hasOwnProperty(key) &&
        record[key as keyof InterviewRecord] === null &&
        key !== "age"
      ) {
        record[key as keyof InterviewRecord] = "　" as never;
      }
    }
    return record;
  }

  return (
    <div className="sidebar">
      <Image alt="logo" src="logo_main.png" margin="20px" width="210px" />
      <View marginLeft="17px" textAlign="left">
        <Button
          variation="primary"
          size="small"
          children="新規作成"
          onClick={() =>
            setRecord({
              __typename: "InterviewRecord",
              id: "",
              intervieweeName: "",
              interviewerName: "",
              interviewDateTime: "",
              channel: "",
              createdAt: "",
              updatedAt: "",
            })
          }
        />
      </View>

      <div className="records">
        <RecordCardCollection
          // 検索対象を名前のみにする
          searchFilter={(item: unknown, text: string) =>
            ((item as InterviewRecord).intervieweeName as string).includes(text)
          }
          overrideItems={({ item, index }) => ({
            style: {
              // cursor: "pointer",
              backgroundColor:
                item.id === record.id
                  ? tokens.colors.brand.primary[20].value
                  : "transparent",
            },

            onClick: async (clickEvent) => {
              if (clickEvent.target instanceof HTMLImageElement) {
                // 画像がクリックされた場合,削除するかどうかを確認する
                if (
                  !window.confirm(
                    `${item.intervieweeName} さんの記録を削除しますか？(削除したデータは復元できません)`
                  )
                ) {
                  return;
                }
                await API.graphql({
                  query: deleteInterviewRecord.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: item.id,
                    },
                  },
                });
                window.location.reload();
              } else {
                // 画像以外がクリックされた場合
                setRecord(checkRecord(item));
              }
            },
            interviewRecord: {
              intervieweeName: item.intervieweeName,
              interviewerName: item.interviewerName,
              createdAt: new Date(item.createdAt).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
                hour12: false,
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }),
              updatedAt: item.updatedAt,
              interviewDateTime: item.interviewDateTime,
              id: item.id,
              __typename: item.__typename,
            },
          })}
        />
      </div>

      {/* ログアウトボタンのポップアップトリガー */}
      <div className="username-box">
        {/* ユーザー名を表示する */}
        <Text fontSize="medium">
          <b>{authenticator.user.username}</b>
        </Text>
        <Icon as={MdMoreHoriz} onClick={toggleLogoutPopup} />
      </div>

      {/* ログアウトボタンのポップアップ */}
      {isLogoutPopupVisible && (
        <div className="logout-popup">
          <Button
            variation="link"
            size="small"
            children="ログアウト"
            onClick={authenticator.signOut}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
